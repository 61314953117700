


















import UserTypeCondition from "@/includes/logic/Modules/models/conditions/UserType/UserTypeCondition";
import { InputSetups } from "@/mixins/input-setups";
import { UserTypeEnum } from "@/includes/logic/Modules/models/conditions/UserType/types";

import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import { SelectOption } from "piramis-base-components/src/logic/types";

import { Component, Mixins, VModel } from 'vue-property-decorator';

@Component({
  components: {
    SelectInput
  }
})
export default class UserTypeConditionView extends Mixins(InputSetups) {
  @VModel() model!: UserTypeCondition

  get userTypeValueOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t('user_type_condition_value_is_premium').toString(),
        value: UserTypeEnum.IsPremium
      },
      {
        label: this.$t('user_type_condition_value_is_not_premium').toString(),
        value: UserTypeEnum.IsNotPremium
      },
    ]
  }
}
