var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-type-condition-view"},[_c('select-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'value',
        'prefix': 'user_type_',
        'options': _vm.userTypeValueOptions,
        'clearable': false,
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }